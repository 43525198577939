import { ref } from 'vue';

export const useLinks = (blok: StoryblokLink) => {
    const localePath = useLocalePath();
    const preparedUrl = ref(blok?.cached_url);

    if (blok?.linktype === 'email') {
        preparedUrl.value = 'mailto:' + blok.email;
    }

    if (blok?.linktype === 'story') {
        const startsWithSlashRegex = new RegExp('^/[a-z]{2}/|^/[a-z]{2}$');
        // when home story remove home from url to get root path
        preparedUrl.value = preparedUrl.value.replace('home', '');

        // when story slug does not start with slash, prepend slash to url
        if (!startsWithSlashRegex.test(preparedUrl.value)) {
            preparedUrl.value = '/' + preparedUrl.value;
        }

        preparedUrl.value = localePath(preparedUrl.value);
    }

    // remove trailing slashed from links, except for home route '/'
    if (preparedUrl.value.length > 1 && preparedUrl.value.endsWith('/')) preparedUrl.value = preparedUrl.value.slice(0, -1);

    const trackClickEvent = () => {
        if (blok?.linktype === 'email') {
            useTrackEvent('contactBtnClicked');
        }
    }

    return {
        preparedUrl,
        trackClickEvent
    }
};